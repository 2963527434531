import React, { Component } from 'react';
import Spinner from '../../Components/UI/Spinner/Spinner';
import classes from './Listen.module.scss';
import Fab from '@material-ui/core/Fab';
import listenwalletwhite from '../../img/Solid.png'
import Lottie from 'react-lottie';
import Quiz from '../Quiz/Quiz';
import { withRouter } from 'react-router-dom';
import Radium, {StyleRoot} from 'radium';
import { fadeInUp, fadeOutUp, bounceInDown, fadeInRight } from 'react-animations';
let mounted = false;
const styles = {
    fadeInUp: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
    },
    fadeOutUp: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeOutUp, 'fadeOutUp')
    },
    bounceInDown: {
        animation: 'x 1s',
        animationName: Radium.keyframes(bounceInDown, 'bounceInDown')
    },
    fadeInRight: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
    }
}

class Listen extends Component {
    streamInterval;
    interval;
    quizInterval
    state = {
        listening: false,
        loading: true,
        isStream: null,
        showCount: false,
        showQuiz: false,
        counter: null,
        externalId: null,
        caughtAt: null,
        elapsed: 0,
        seconds: 0
    }
    componentDidMount() {
        mounted = true;
        this.startInterval();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.isStream !== nextState.isStream) {
            if (nextState.isStream === false) {
                window.location.reload();
            } 
            if (nextState.isStream === true) {
                this.setState({loading: false});
            } 
        }
        return true;
    }
    startInterval = () => {
        this.interval = setInterval(() => {
            if (window && window.audioIdEx && window.audioIdEx.start) {
                clearInterval(this.interval)
                this.onListen();
            }
        }, 500)
        this.streamInterval = setInterval(() => {
            if (this.state.isStream !== window.audioIdEx.isStream) {
                this.setState({isStream: window.audioIdEx.isStream})
            }
        }, 500);
    }

    startListening() {
        this.setState({
            listening: true,
            value: 'Stop',
            dataState: 'started',
            listeningClass: 'pulse',
            result: false,
            loading: false,
            startListening: false,
        });
    }

    stopListening() {
        window.audioIdEx.stop(this.cb.bind(this));
        this.setState({
            listening: false,
            value: 'Start',
            dataState:'stopped',
            listeningClass: 'listen-button-notactive',
            startListening: false
        })
    }

    onListen = () => {
        if (this.state.listening === false && !this.state.startListening) {
            this.setState({
                loading: true,
                startListening: true,
            })
            // the start function takes 3 parameters (callback, searchfp, searchsc - watermark)
            if (mounted) {
                window.audioIdEx.start(this.cb.bind(this), 0, 1);
            } else {
                window.audioIdEx.stop(this.cb.bind(this));
                return;
            }
        } else {
            this.stopListening();
        }
    }

    cb = (data) => {
        if (mounted) {
            this.startListening();
        } else {
            window.audioIdEx.stop(this.cb.bind(this));
            return;
        }
        let field = document.getElementById('system_time');
        let t = new Date();
        let hours = t.getHours(); if (hours < 10) hours = "0" + hours;
        let min = t.getMinutes(); if (min < 10) min = "0" + min;
        let sec = t.getSeconds(); if (sec < 10) sec = "0" + sec;
        field.innerHTML = "System time: " + hours + ":" + min + ":" + sec;
        if (data.code !== -1) {
            console.log(data)
            let shift = data.timestamp/1000;
            let shift_h = Math.floor(shift / 3600); if (shift_h < 10) shift_h = "0" + shift_h;
            let shift_m = Math.floor(shift / 60); if (shift_m < 10) shift_m = "0" + shift_m;
            let shift_s = Math.floor(shift % 60); if (shift_s < 10) shift_s = "0" + shift_s;
            if (data.src === "watermark") {
                field = document.getElementById('sc_answer');
                let externalId =  Math.floor(data.code/2048);
                this.setState({externalId: externalId})
                var counter = data.code & 0xFF;
                this.setState({counter: counter})
                if (!this.state.caughtAt && (counter > 0 && counter < 6)) {
                    window.audioIdEx.stop(this.cb.bind(this));
                    clearInterval(this.quizInterval)
                    this.setTimer(counter);
                }
                if (counter >= 6 && !this.caughtAt) {
                    this.stopListening();
                    clearInterval(this.quizInterval)
                    setTimeout(() => {
                        this.props.history.push('/done');
                    }, 1000)
                }

            }

        }
    }
    countDown = () => {
        clearInterval(this.quizInterval);
        if (this.state.listening) {
            this.stopListening() 
        }
        setTimeout(() => {
            this.setState({
                showCount: false,
                showQuiz: true,
                listening: false,
                value: 'Start',
                dataState:'stopped',
                listeningClass: 'listen-button-notactive',
                startListening: false
            });
        }, 3000)
    }
    setTimer = (counter) => {
        if (!this.state.caughtAt) {
            this.setState({
                caughtAt: counter,
                caughtTime: Date.now()
            });
            this.quizInterval = setInterval(this.tick, 500);
        } 
    }
    tick = () => {
        var elapsed = Math.round((new Date() - this.state.caughtTime) / 100);
        var seconds = (elapsed / 10).toFixed(1); 
        if (this.state.caughtAt === 1 && seconds >= 15) {
            this.setState({showCount: true});
            this.countDown();
        }
        if (this.state.caughtAt === 2 && seconds >= 8) {
            this.setState({showCount: true});
            this.countDown()
        }
        if (this.state.caughtAt === 3 && seconds >= 6) {
            this.setState({showCount: true});
            this.countDown();
        }
        if (this.state.caughtAt > 3) {
            this.setState({showQuiz: true});
        } 
        this.setState({elapsed: seconds});
    }
    componentWillUnmount = () => {
        mounted = false;
        clearInterval(this.quizInterval)
        clearInterval(this.streamInterval);
        clearInterval(this.interval)
        if (this.state.listening) {
            this.stopListening();
        }
        if (this.state.startListening) {
            this.setState({
                listening: false,
                value: 'Start',
                dataState:'stopped',
                listeningClass: 'listen-button-notactive',
                startListening: false,
                isStream: null
            })
        }
    }
    render() {
        const CountOptions = {
            loop: false,
            autoplay: true, 
            animationData: require('./countdown.json'),
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
        };
        const fab = (
            <div>
                <Fab      
                    data-state={this.state.dataState}
                    id="audioButtonId" value={this.state.value}
                    className="pulse"
                    aria-label="mic"
                    disabled={true}>
                        {
                            this.state.showCount ?  <Lottie options={CountOptions} height={200} width={200} /> :
                            <img height={'72px'} width={'72px'} src={listenwalletwhite} alt="listen-icon"/>
                        }
                </Fab>
            </div>
            
        )

        return (
            <div className={classes.ListenContainer}>
                <div className={classes.CifraResponse} id="system_time">no data...</div>
                <div className={classes.CifraResponse} id="fp_answer"></div>
                <div className={classes.CifraResponse} id="sc_answer"></div>
                <StyleRoot>
                    {
                        !this.state.showQuiz ? (
                            <div>
                                {
                                    this.state.loading ?
                                    <Spinner /> 
                                    :
                                    <div>
                                        <div style={{
                                            marginTop: (this.props.height < this.props.width) && this.props.isMobile ? '20px' : '121px' 
                                        }} className={classes.CopyContainer}>
                                            {
                                                this.state.isStream === false ? 
                                                '' :
                                                <div>
                                                    {
                                                        !this.state.caughtAt ?
                                                        <div className={classes.TitleWaiting} style={styles.fadeInUp}>Getting ready to sync...</div> : <div className={classes.TitleReady} style={styles.fadeInUp}>Synced, get ready!</div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div style={{
                                                    animation: 'x 1s',
                                                    animationName: Radium.keyframes(bounceInDown, 'bounceInDown'),
                                                    marginTop: (this.props.height < this.props.width) && this.props.isMobile ? '50px' : '150px'
                                        }} className="button-container">
                                            {fab}
                                        </div>
                                    </div>
                                }
                            </div>
                        ) : (
                            <Quiz
                                isMobile={this.props.isMobile}
                                height={this.props.height}
                                width={this.props.width}
                                id={this.state.externalId}
                                navigate={() => this.props.history.push('/done')}
                                counter={this.state.counter}
                                caughtAt={this.state.caughtAt}
                                seconds={this.state.seconds} />
                        )
                    }
                </StyleRoot>

            </div>
        );
    }
}

export default withRouter(Listen);
