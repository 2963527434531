import React, { Component } from 'react';
import classes from './Done.module.scss';
import ReactGA from 'react-ga';

export default class Done extends Component {
  componentDidMount() {
    ReactGA.event({
      category: "Appplication Actions",
      action: `Display View: Thanks for Playing`,
      label: 'Follow us on instagram werfrontandcenter'
  });
  }
  render() {
    return (
      <div className={classes.DoneContainer}>
        <div className={classes.ThanksTitle}>Thanks for Playing</div>
        <div>
          <div>Follow us on instagram</div>
          <div>@werfrontandcenter</div>
        </div>
      </div>
    );
  }
}
