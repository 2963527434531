import React from 'react';

const Modal = (props) => {
    return (
        <div
            className={props.landing ? "screenvision--modal" : "quiz--modal"}
            style={{
                transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: props.show ? '1' : '0',
                display: !props.show ? 'none' : 'inline-block',
                marginTop: props.height < props.width && props.isMobile ? '0px' : !props.landing && props.isMobile  ? '20px' : props.isMobile && props.landing ? '75px' : '123px',
                height: props.height < props.width && props.isMobile ? '100%' : !props.landing ? '550px' : '400px',
                width: !props.landing && props.height < props.width && props.isMobile ? '100%' : '340px'
            }}>
            {props.children}
        </div>
    );
}

export default Modal;
