import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom'; 
import ReactGA from 'react-ga';

const trackingId = 'UA-51654560-5'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const app = (
    <Router>
        <App />
    </Router>
)
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
