import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff"
    },
    background: {
        paper: "#fff",
        default: "#fafafa"
    },
    primary: {
        light: "rgba(81, 143, 229, 1)",
        main: "#02B4FF",
        dark: "rgba(5, 77, 208, 1)",
        contrastText: "#fff",
    },
    secondary: {
        light: "#fff",
        main: "#fff",
        dark: "#fff",
        contrastText: "#075FDA"
    },
    error: {
      light: "#e57373",
      main: "#a02222",
      dark: "#4C1010",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 1)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  }
});

export default theme;