import React, { Component } from 'react';
import classes from './QuizAnswer.module.scss';

class QuizAnswer extends Component {

    handleSelect = () => {
        if(!this.props.disabled) {
            this.props.click(this.props.letter)
        }
    }
    render() {
        let classie = [classes.QuizAnswerContainer, this.props.background]
        if (this.props.disabled) {
            classie.push('greyText')
        }
        let ansClass = classie.join(' ')

        return (
            <div onClick={this.handleSelect} className={ansClass}>
                <div className={classes.Letter}>
                    {this.props.letter}
                </div>
                <div>
                    {this.props.answer}
                </div>
            </div>
    
        );
    }
}

export default QuizAnswer;
