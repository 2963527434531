import React from 'react';
import './App.scss';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Landing from './Components/Landing/Landing';
import { Route, Switch } from 'react-router-dom'; 
import Listen from './Containers/Listen/Listen';
import Quiz from './Containers/Quiz/Quiz';
import Done from './Components/Done/Done';
import packageJson from '../package.json';
import CacheBuster from './CacheBuster';
global.appVersion = packageJson.version;

function App() {
  const routes  = (
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route path="/listen" exact component={Listen} />
      <Route path="/quiz" exact component={Quiz} />
      <Route path="/done" exact component={Done} />
    </Switch>
  )
  return (
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <div className="App">
              <MuiThemeProvider theme={theme}>
                  {routes}
              </MuiThemeProvider>
            </div>
          );
        }}
    </CacheBuster>
  );
}

export default App;
