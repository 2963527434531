import React, { Component } from 'react'
import Modal from '../../Components/UI/Modal/Modal';
import classes from './Quiz.module.scss';
import QuizAnswer from '../../Components/UI/QuizAnswer/QuizAnswer';
import ReactGA from 'react-ga';

class Quiz extends Component {
    state = {
        selectedAnswer: '',
        status: 'Active',
        selectedName: '',
        correctAnswer: '',
        question: '',
        answers: {
            A: {
                selected: false,
                name: 'Bill Hader',
                value: 'A',
                background: 'not-selected'
            },
            B: {
                selected: false,
                name: 'Kristen Wigg',
                value: 'B',
                background: 'not-selected'
            },
            C: {
                selected: false,
                name: 'Amy Poehler',
                value: 'C',
                background: 'not-selected'
            },
            D: {
                selected: false,
                name: 'Will Forte',
                value: 'D',
                background: 'not-selected'
            }
        },
        disabled: false
    }
    componentDidMount() {
        let updatedAnswers = {...this.state.answers}
        let question = ''
        let correctAnswer = '';
        if (this.props.id === 21020) {
            updatedAnswers['A'].name = "John Cena";
            updatedAnswers['B'].name = "Triple H";
            updatedAnswers['C'].name = "Dave Bautista";
            updatedAnswers['D'].name = "Hulk Hogan";
            question = 'Which actor turned wrestler will star alongside Dwayne "The Rock" Johnson in Fast and Furious 9?';
            correctAnswer = 'A'
        } else if (this.props.id === 2001211) {
            updatedAnswers['A'].name = "Bill Hader";
            updatedAnswers['B'].name = "Kristen Wigg";
            updatedAnswers['C'].name = "Amy Poehler";
            updatedAnswers['D'].name = "Will Forte";
            question = 'Which SNL star will have a supporting role in the upcoming film Wonder Woman: 1984?';
            correctAnswer = 'B'    
        } else {
            //200313
            updatedAnswers['A'].name = "Christina Aguilera";
            updatedAnswers['B'].name = "Beyonce";
            updatedAnswers['C'].name = "Britney Spears";
            updatedAnswers['D'].name = "Jennifer Lopez";
            question = 'Which pop singer appears on both the 1998 and 2020 Mulan soundtracks?';
            correctAnswer = 'A'    
        }

        this.setState({
            correctAnswer: correctAnswer,
            question: question,
            answers: updatedAnswers
        })
        if (this.props.id) {
            ReactGA.event({
                category: "Appplication Actions",
                action: `Display View: Trivia Card ${this.props.id}`,
                label: `${this.state.question}`
            });
        }
        setTimeout(()=> {
            let disabled = this.state.disabled;
            if (!disabled) {
                this.setAnswered();
            }
        }, this.props.caughtAt === 5 ? 5000 : this.props.caughtAt === 4 ? 11000 : 12500)
    }
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.counter !== null
    }
    setAnswered = () => {
        let status;
        status = this.state.selectedAnswer === this.state.correctAnswer ? 'Correct' : this.state.selectedAnswer === '' ? 'None' : 'Wrong'

        const updatedAnswers ={
            ...this.state.answers
        }
        for (let key in updatedAnswers) {
            if (key === this.state.correctAnswer) {
                updatedAnswers[key].background = 'quiz--answer--selected';
            }
            if (status === 'Wrong') {
                updatedAnswers[this.state.selectedAnswer].background = 'quiz--answer--wrong';
            }
        }
        this.setState({
            status: status,
            answers: updatedAnswers,
            disabled: true
        });
        ReactGA.event({
            category: "User Interactions",
            action: `Response to trivia question ${this.props.id}`,
            label: this.state.selectedName
        });
        setTimeout(() => {
            this.props.navigate()
        }, 3000)
    }
    handleSelect = (e) => {
        this.setState({
            selectedAnswer: e,
            selectedName: this.state.answers[e].name
        })
        const updatedAnswers = {
            ...this.state.answers
        }
        for (let key in updatedAnswers) {
            if (key !== e) {
                updatedAnswers[key].selected = false;
                updatedAnswers[key].background = 'not-selected'
            }
            if (key === e) {
                updatedAnswers[key].selected = true;
                updatedAnswers[key].background = 'quiz--answer--selected'
            }
        }
        this.setState({
            answers: updatedAnswers
        })
    }
    
    render() {
        const answersArray = [];
        for (let key in this.state.answers) {
            answersArray.push({
                id: key,
                config: this.state.answers[key]
            })
        }
        const selections = answersArray.map((answer, i) => (
            <QuizAnswer
                isMobile={this.props.isMobile}
                height={this.props.height}
                widht={this.props.width}
                mop={answer}
                key={answer.id}
                letter={answer.config.value}
                answer={answer.config.name}
                click={(letter) => this.handleSelect(letter)}
                selected={answer.config.selected} 
                background={answer.config.background}
                disabled={this.state.disabled} />
        ));
        const titleBadge = (
            <div>
                {
                    this.state.status === 'Active' ? (
                        <div className={classes.QuizTitleContainer}>
                            <div className={classes.QuizTitle}>
                                Quiz
                            </div>
                        </div>
                    ) : this.state.status === 'Wrong' ? (
                        <div className={classes.WrongTitleContainer}>
                            <div className={classes.WrongTitle}>
                                Wrong
                            </div>
                        </div>
                    ) :  this.state.status === 'Correct' ? (
                        <div className={classes.CorrectTitleContainer}>
                            <div className={classes.WrongTitle}>
                                Correct
                            </div>
                        </div>
                    ) : (
                        <div className={classes.NoAnswerContainer}>
                            <div className={classes.WrongTitle}>
                                No Answer
                            </div>
                        </div>
                    )

                }
            </div>
        )
        return (
            <Modal height={this.props.height} width={this.props.width} isMobile={this.props.isMobile} isLanding={false} show={true}>
                <div className={classes.QuizModalContainer}>
                    {titleBadge}
                    <div style={{
                        flexDirection: (this.props.height < this.props.width) && this.props.isMobile ? 'row' : 'column'
                    }} className={classes.QuizContainer}>
                        <div style={{ 
                            widht: (this.props.height < this.props.width) && this.props.isMobile ? '320px' : '50%',
                            textAlign: (this.props.height < this.props.width) && this.props.isMobile ? 'left' : 'center',
                            padding: (this.props.height < this.props.width) && this.props.isMobile ? '25px' : '0px'
                        }} className={classes.QuizQuestion}>
                            {this.state.question}
                        </div>
                        <div style={{ 
                            widht: (this.props.height < this.props.width) && this.props.isMobile ? '50%' : '100%',
                            marginLeft: (this.props.height < this.props.width) && this.props.isMobile ? '16px' : '23px',
                            marginRight: (this.props.height < this.props.width) && this.props.isMobile ? '16px' : '23px',
                            marginTop: '0px'
                        }}
                        className={classes.AnswerContainer}>
                            {selections}
                        </div>
                    </div>

                </div>
            </Modal>
        )
    }
}
export default Quiz;